<script>


/**
 * Basic Info Edit component
 */


import {
    required,
} from "vuelidate/lib/validators";




export default {

    emits: ['confirm', 'cancel'],

    props: ['transaction'],
    components: {
       
    },

    validations() {
        if (this.transaction.type == 'CHEQUE') {
            return {
                payee: { required },
                address: { required },
                inst: {},
                transit: {},
                account: {},
            }
        } else {
            return {
                payee: {},
                address: {},
                inst: { required },
                transit: { required },
                account: { required },
            }
        }

    },



    data() {

        return {
            account : '',
            transit : '',
            inst    : '',
            payee   : '',
            address : '',

        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$error == false) {
                this.$emit('confirm', this.account)
            }
        },

    },

    created() {


    },

    mounted() {

        this.payee   = this.transaction.payee
        this.address = this.transaction.address
        this.inst    = this.transaction.institution
        this.transit = this.transaction.transit
        this.account = this.transaction.bank_account
    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Bank Information</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-md-5">
                        <div>
                            <div class="form-check mb-2">
                                <input class="form-check-input" type="radio" value="CHEQUE" v-model="transaction.type" name="type"
                                    checked />
                                <label class="form-check-label">
                                    CHEQUE
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div>
                            <div class="form-check mb-2">
                                <input class="form-check-input" type="radio" value="EFT" name="type" v-model="transaction.type"
                                    checked />
                                <label class="form-check-label">
                                    EFT
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- end row -->


                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Payee</label>
                            <input  :value="payee" type="text"  class="form-control" placeholder="" readonly />
                            
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Amount</label>
                            <input  :value="transaction.amount.toLocaleString()" type="text"  class="form-control" placeholder="" readonly />
                            
                        </div>
                    </div>
                
                </div>
                 <!-- end row -->

                <div class="row" v-if="transaction.type == 'CHEQUE'">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label >Payee</label>
                            <input  v-model="payee" type="text"
                                class="form-control" placeholder="" :class="{  'is-invalid': $v.payee.$error,  }" />
                            <div v-if="$v.payee.$error" class="invalid-feedback">
                                <span v-if="!$v.payee.required">This value is required.</span>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom03">Address</label>
                            <input id="validationCustom03" v-model="address" type="text"
                                class="form-control" placeholder="" :class="{  'is-invalid': $v.address.$error,  }" />
                            <div v-if="$v.address.$error" class="invalid-feedback">
                                <span v-if="!$v.address.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" v-if="transaction.type == 'EFT'">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom03">Institution</label>
                            <input id="validationCustom03" v-model="inst" type="text"
                                class="form-control" placeholder="" :class="{  'is-invalid': $v.inst.$error,  }" />
                            <div v-if="$v.inst.$error" class="invalid-feedback">
                                <span v-if="!$v.inst.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom04">Transit</label>
                            <input id="validationCustom04" v-model="transit" type="text" class="form-control"
                                placeholder="" :class="{
                'is-invalid': $v.transit.$error,
            }" />
                            <div v-if=" $v.transit.$error" class="invalid-feedback">
                                <span v-if="!$v.transit.required">This value is required.</span>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom05">Account</label>
                            <input id="validationCustom05" v-model="account" type="text" class="form-control"
                                placeholder="" :class="{
                'is-invalid': $v.account.$error,
            }" />
                            <div v-if=" $v.account.$error" class="invalid-feedback">
                                <span v-if="!$v.account.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- end row-->

               



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>