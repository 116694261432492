
<script>


/**
 * Transfer Dialog component
 */


import simplebar from "simplebar-vue";

import { getCashApi } from '@/api/cash'

export default {

    emits: ['confirm', 'cancel'],

    props: ['transactions'],
    components: {
        simplebar
    },

    validations: {
       
    },



    data() {
        return {
            transaction_number: '',
            total_amount : 0,
            description  : '',
            remit_bank_account: {
                name : ''
            }, 
            receive_bank_account : {
                name : ''
            },

            is_submitting : false,
            current_progress : 10,
            task_id : '',
            api_error : 0,
        }
    },

    methods: {
        formSubmit() {
           
            this.$v.$touch();
            if (this.$v.$error == false) {
               
                this.make_transfer()
            }
        },

        make_transfer() {
            let data = {
                transactions: [],
                description : this.description
            }

            this.transactions.map(r => {
                data.transactions.push(r.id)
            })
            this.is_submitting = true
            this.$bvModal.show('CloseProgressDiloag')

            getCashApi().make_pm_mgt_fee_transfer(data).then((res) => {
                clearInterval(this.task_id)
                if (res.errCode == 0) {
                    this.task_id = setInterval(this.queryProgress, 2000)
                
                } else {
                    this.is_submitting = false
                    this.$bvModal.hide('CloseProgressDiloag')
                    this.$alertify.error("Make PM Transfer Failed " + res.errCode);
                }
            })
        },

        queryProgress() {
        
            getCashApi().pm_mgt_fee_transfer_status_query({}).then(res => {
                if (res.errCode == 0) {
                    if (res.data.error) {
                        this.current_progress = 100
                        clearInterval(this.task_id)
                        this.$alertify.message("PM Trasnfer Failed, please re-do Transfer ");
                        this.$bvModal.hide('CloseProgressDiloag')
                       
                        this.is_submitting = false
                    } else {
                        if (res.data.percentage == 100 || res.data.status =='FINISHED') {
                            this.$bvModal.hide('CloseProgressDiloag')
                            clearInterval(this.task_id)
                            this.$alertify.message("PM Trasnfer  successfully");
                            this.is_submitting = false
                            this.$emit('confirm')
                        } else {
                            this.current_progress = res.data.percentage
                        }
                    }
                } else {
                    this.api_error++
                    if (this.api_error > 5) {
                        this.$bvModal.hide('CloseProgressDiloag')
                        clearInterval(this.task_id)
                        this.is_submitting = false
                        this.$alertify.error("Make PM Transfer Failed " + res.errCode);
                    }
                }
            })
        },


    },

    created() {
    },

    mounted() {
        this.api_error = 0
        this.remit_bank_account =  {name : 'PM Trust'}
        this.receive_bank_account = {name : 'Commission'}

        if (this.transactions && this.transactions.length > 0) {
            this.total_amount = this.transactions.reduce((a, c) => a += c.amount, 0)
        }
    },
    unmounted() {
        clearInterval(this.task_id)
    },

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <b-modal centerd id="CloseProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
                <b-progress height="20px" :value="current_progress"></b-progress>
            </b-modal>
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">From</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder="" readonly :value="remit_bank_account.name" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">To</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder="" readonly :value="receive_bank_account.name" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder="" readonly :value="'$'+total_amount.toLocaleString()" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Code </label>
                            <input id="validationCustom01" v-model="transaction_number" type="text" class="form-control"
                                placeholder=""  maxlength="9" />
                           
                        </div>
                    </div>

                    <div class="col-md-10">
                        <div class="mb-3">
                            <label for="validationCustom01">Description </label>
                            <input id="validationCustom01" v-model="description" type="text" class="form-control"  placeholder=""  />
                           
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%" >
                            <b-table :items="transactions" :fields="['Amount', 'Description']" responsive="sm" :per-page="transactions.length" 
                            class="table-check">

                                <template #cell(Amount)="data">
                                    ${{ data.item.amount.toLocaleString() }}
                                </template>
                                <template #cell(Description)="data">
                                    {{ data.item.remarks + ' Agent('+ data.item.agent_name + ') Mgt Fee ' }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit" :disabled="is_submitting">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>